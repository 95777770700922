import * as React from 'react'
import VideoCardGrid from '../cms/VideoCardGrid'
import { processGatsbyImageDataMock } from '../../common/gatsbyImageData'
import loadable from '@loadable/component'
const Section = loadable(() => import('../cms/Section'))
import { CombineSections } from '../cms/SectionLayout'
import useInViewPort from '../../hooks/useInViewPort'
import PropTypes from 'prop-types'

const CategoryDescription = ({ category }) => {
  const pageSections = CombineSections(category.sections || [])
  const videos = category.videos?.videos || []
  videos.forEach(video => { processGatsbyImageDataMock(video.thumbnail) })
  const [descRef, isDescVisible] = useInViewPort()
  return (
    <>
      <div className={'mx-auto w-10/12 cms order-2 flex flex-col gap-10 duration-350 ease-in-out ' + (isDescVisible ? 'min-h-auto':'min-h-screen')} ref={descRef}>
        {isDescVisible ? (
          <div className="" dangerouslySetInnerHTML={{ __html: category.description }} />
        ) : 
          (<div className="h-full">Loading...</div>)
        }
      </div>
      {pageSections && isDescVisible &&
      pageSections.map(section => {
        return (
          <Section
            key={section.id}
            section={section}
            className={'w-10/12 max-w-[1366px] mx-auto relative'}
            fallback={<div className="h-full">Loading...</div>}
          />
        )
      })}
      {isDescVisible && (
        <div className="mx-auto w-10/12 cms order-2 flex flex-col gap-10">
          <VideoCardGrid videos={videos} />
          <div className="" dangerouslySetInnerHTML={{ __html: category.descriptionBottom }} />
        </div>)}
    </>
  )
}
CategoryDescription.propTypes = {
  category: PropTypes.object.isRequired,
}
export default CategoryDescription
